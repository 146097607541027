@import './_variables.scss';

@mixin media($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin mobile {
  @include media($mobile-width) {
    @content;
  }
}

@mixin tablet {
  @include media($tablet-width) {
    @content;
  }
}

@mixin laptop {
  @include media($laptop-width) {
    @content;
  }
}

@mixin desktop {
  @include media($desktop-width) {
    @content;
  }
}

@mixin pc {
  @include media($pc-width) {
    @content;
  }
}

@mixin flex-items-center {
  display: flex;
  align-items: center;
}

@mixin flex-items-start {
  display: flex;
  align-items: flex-start;
}

@mixin flex-items-end {
  display: flex;
  align-items: flex-end;
}

@mixin flex-items-stretch {
  display: flex;
  align-items: stretch;
}

@mixin flex-justify-center {
  display: flex;
  justify-content: center;
}

@mixin flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

@mixin flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

@mixin flex-justify-stretch {
  display: flex;
  justify-content: stretch;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin mix-padding($position: false) {
  @if $position == left {
    padding-left: 16px;
    @include tablet {
      padding-left: 20px;
    }
  } @else if $position == bottom {
    padding-bottom: 16px;
    @include tablet {
      padding-bottom: 20px;
    }
  } @else if $position == right {
    padding-right: 16px;
    @include tablet {
      padding-right: 20px;
    }
  } @else if $position == top {
    padding-top: 16px;
    @include tablet {
      padding-top: 20px;
    }
  } @else {
    padding: 16px;
    @include laptop {
      padding: 20px;
    }
  }
}

@mixin heading-1 {
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
}

@mixin heading-2 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
}

@mixin heading-3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

@mixin heading-4 {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
}

@mixin heading-5 {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
}

@mixin heading-6 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

@mixin heading-7 {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
}

@mixin heading-8 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

@mixin text-medium-medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-large-regular {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

@mixin text-medium-regular {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-small-regular {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-tiny-regular {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin text-large-bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

@mixin text-medium-bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

@mixin text-small-bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
}

@mixin text-tiny-bold {
  font-weight: 700;
  font-size: 12px;
  line-height: 26px;
}

@mixin text-typography($font-weight, $font-size, $line-height) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin regular-h1 {
  font-family: 'DM Sans Regular';
  @include text-typography(400, 58px, 68px);
}

@mixin regular-h2 {
  font-family: 'DM Sans Regular';
  @include text-typography(400, 45px, 55px);
}

@mixin regular-h3 {
  font-family: 'DM Sans Regular';
  @include text-typography(400, 40px, 54px);
}

@mixin regular-h4 {
  font-family: 'DM Sans Regular';
  @include text-typography(400, 32px, 40px);
}

@mixin regular-h5 {
  font-family: 'DM Sans Regular';
  @include text-typography(400, 24px, 32px);
}

@mixin regular-h6 {
  font-family: 'DM Sans Regular';
  @include text-typography(400, 20px, 30px);
}

@mixin regular-h7 {
  font-family: 'DM Sans Regular';
  @include text-typography(400, 18px, 26px);
}

@mixin regular-h8 {
  font-family: 'DM Sans Regular';
  @include text-typography(400, 16px, 24px);
}

@mixin regular-h9 {
  font-family: 'DM Sans Regular';
  @include text-typography(400, 14px, 20px);
}

@mixin regular-h10 {
  font-family: 'DM Sans Regular';
  @include text-typography(400, 12px, 18px);
}

@mixin medium-h1 {
  font-family: 'DM Sans Medium';
  @include text-typography(500, 58px, 68px);
}

@mixin medium-h2 {
  font-family: 'DM Sans Medium';
  @include text-typography(500, 45px, 55px);
}

@mixin medium-h3 {
  font-family: 'DM Sans Medium';
  @include text-typography(500, 40px, 54px);
}

@mixin medium-h4 {
  font-family: 'DM Sans Medium';
  letter-spacing: -0.5px;
  @include text-typography(500, 32px, 40px);
}

@mixin medium-h5 {
  font-family: 'DM Sans Medium';
  @include text-typography(500, 24px, 32px);
}

@mixin medium-h6 {
  font-family: 'DM Sans Medium';
  @include text-typography(500, 20px, 30px);
}

@mixin medium-h7 {
  font-family: 'DM Sans Medium';
  @include text-typography(500, 18px, 26px);
}

@mixin medium-h8 {
  font-family: 'DM Sans Medium';
  @include text-typography(500, 16px, 24px);
}

@mixin medium-h9 {
  font-family: 'DM Sans Medium';
  @include text-typography(500, 14px, 20px);
}

@mixin medium-h10 {
  font-family: 'DM Sans Medium';
  @include text-typography(500, 12px, 18px);
}

@mixin bold-h1 {
  font-family: 'DM Sans Bold';
  @include text-typography(700, 58px, 68px);
}

@mixin bold-h2 {
  font-family: 'DM Sans Bold';
  @include text-typography(700, 45px, 55px);
}

@mixin bold-h3 {
  font-family: 'DM Sans Bold';
  letter-spacing: -1px;
  @include text-typography(700, 40px, 54px);
}

@mixin bold-h4 {
  font-family: 'DM Sans Bold';
  @include text-typography(700, 32px, 40px);
}

@mixin bold-h5 {
  font-family: 'DM Sans Bold';
  @include text-typography(700, 24px, 32px);
}

@mixin bold-h6 {
  font-family: 'DM Sans Bold';
  @include text-typography(700, 20px, 30px);
}

@mixin bold-h7 {
  font-family: 'DM Sans Bold';
  @include text-typography(700, 18px, 26px);
}

@mixin bold-h8 {
  font-family: 'DM Sans Bold';
  @include text-typography(700, 16px, 24px);
}

@mixin bold-h9 {
  font-family: 'DM Sans Bold';
  @include text-typography(700, 14px, 20px);
}

@mixin bold-h10 {
  font-family: 'DM Sans Bold';
  @include text-typography(700, 12px, 18px);
}
