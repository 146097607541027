@import './_color-variables.scss';
@import './_mixins.scss';

.main-landing {
  padding-bottom: 50px;
  font-family: 'Anderson Grotesk Light', sans-serif;
  max-width: 1178px;
  margin: 0 auto;

  .introduction {
    margin: 0 auto;
    padding-top: 260px;
    @include tablet {
      padding-top: 108px;
    }

    &__main-text {
      font-weight: 400;
      font-size: 80px;
      line-height: 120%;
      text-align: center;
      color: $title;
      max-width: 940px;
      margin: 0 auto;
      margin-top: 8px;
      font-family: 'Spline Sans Mono', sans-serif;

      span {
        color: $link;
      }

      @include tablet {
        font-size: 48px;
      }
    }

    .price-text {
      max-width: 1128px;
    }

    .list-package {
      margin-top: 80px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 160px;
      @include tablet {
        margin-top: 40px;
        margin-bottom: 96px;
        flex-direction: column;
        align-items: center;

        &::-webkit-scrollbar {
          display: none;
        }

        .package {
          margin-bottom: 24px;
        }
      }

      .package {
        position: relative;
        overflow: hidden;
        max-width: 264px;
        min-width: 264px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0%) -24.6%,
          rgba(34, 108, 255, 24%) 100%
        );
        border: 1px solid rgba(34, 108, 255, 30%);
        border-radius: 26px;
        font-family: 'Anderson Grotesk Light', sans-serif;
        padding: 54px 22px 60px;
        backdrop-filter: blur(5px);
        display: flex;
        flex-direction: column;
        box-shadow: 0 20px 20px #88e1dc14;
        transition: all ease 0.3s;

        &:hover {
          cursor: pointer;
          filter: drop-shadow(0 18px 24px rgba(136, 225, 220, 8%));
          border: 1px solid #226cff;
          box-shadow: 0 20px 30px #226cff4d;
        }

        .name-package {
          color: #fffc;
          text-align: center;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0.12em;
        }

        .price-package-wrap {
          margin-top: 14px;
          text-align: center;
          margin-bottom: 34px;
          display: flex;
          justify-content: center;
          flex-direction: row;
          color: $title;

          .price-package {
            font-family: 'Anderson Grotesk Bold', sans-serif;
            text-align: center;
            position: relative;
            font-size: 50px;
            font-weight: 700;
          }

          .currency-package {
            font-size: 30px;
            line-height: 30px;
            padding-top: 8px;
          }

          .time-package {
            font-size: 26px;
            font-weight: 400;
            display: flex;
            align-items: flex-end;
            padding-bottom: 10px;
          }
        }

        .service-package {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          gap: 11px;
          color: #fff9;

          &:last-child {
            margin-bottom: 0;
          }

          .service-content {
            font-family: 'Anderson Grotesk Light', sans-serif;
            font-weight: 400;
            font-size: 16px;
          }
        }

        .badge-package {
          position: absolute;
          left: -40px;
          top: 20px;
          background: $main;
          width: 150px;
          display: flex;
          justify-content: center;
          padding: 4px 0;
          transform: rotate(-45deg);
          letter-spacing: 2px;
          color: $title;
        }
      }
    }

    .network-support {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      margin-bottom: 160px;
      @include tablet {
        margin-bottom: 120px;
      }
    }

    .plan-table {
      margin-top: 60px;
      margin-bottom: 160px;
      border-radius: 16px;
      backdrop-filter: blur(8px);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0%) -24.6%,
        rgba(34, 108, 255, 24%) 100%
      );
      border: 1px solid rgba(34, 108, 255, 30%);
      filter: drop-shadow(0 18px 24px rgba(136, 225, 220, 8%));
      box-shadow: 0 20px 20px #88e1dc14;

      .plan-row {
        display: flex;
        justify-content: center;
        font-size: 18px;
        border-bottom: 1px solid #3b528a;
        color: $title;

        &:first-child,
        &:last-child {
          border-bottom: none;
        }

        .name-plan-cell {
          padding-left: 40px;
          width: 28%;
          min-height: 70px;
          display: flex;
          align-items: center;
          justify-content: left;
        }

        .free-plan-cell,
        .starter-plan-cell,
        .growth-plan-cell,
        .pro-plan-cell {
          width: 18%;
          flex: 1;
          min-height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .title-plan {
          color: #8d91a5;
          font-size: 16px;
        }

        .active-plan {
          color: $link;
          display: flex;
          align-items: center;

          :last-child {
            margin-left: 10px;
          }

          div {
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }

    .button-network-wrap {
      display: flex;
      justify-content: center;
      margin-top: 72px;
      margin-bottom: 110px;

      @include tablet {
        margin-bottom: 70px;
      }

      .button-network {
        cursor: pointer;
        text-align: center;
        width: 156px;
        font-weight: 700;
        font-size: 18px;
        color: $title;
        background: linear-gradient(225deg, #226cff 14.89%, #1084ff 85.85%);

        &:hover {
          background: linear-gradient(225deg, #226cff 64.89%, #1084ff 95.85%);
        }

        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        color: $title;

        // :first-child {
        //   margin-right: 10px;
        // }
      }
    }

    .list-plan-mobile {
      margin-top: 40px;
      margin-bottom: 120px;

      .content-plan-wrap {
        color: $title;
        margin-bottom: 16px;

        span {
          color: $title;
        }

        .content-plan-item {
          &:first-child {
            margin-top: 10px;
          }

          color: #fff9;
          font-size: 16px;
          display: flex;
          align-items: center;
          padding-bottom: 18px;

          div.icon-tone {
            margin-right: 13px;
          }
        }

        .active-plan-btn {
          margin-top: 6px;
          color: $link;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 18px;
          padding-bottom: 8px;
          padding-top: 22px;
          border-top: 1px dashed #5a74b280;

          :last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .desktop-ui {
    display: block;
    @include tablet {
      display: none;
    }
  }

  .mobile-ui {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
