@import './_color-variables.scss';
@import './_mixins.scss';

* {
  // line-height: 140%;
  box-sizing: border-box;
}

@font-face {
  font-family: 'DM Sans Bold';
  src: url('/fonts/DMSans-Bold.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans Bold Italic';
  src: url('/fonts/DMSans-BoldItalic.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans Italic';
  src: url('/fonts/DMSans-Italic.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans Medium';
  src: url('/fonts/DMSans-Medium.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans Medium Italic';
  src: url('/fonts/DMSans-MediumItalic.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans Regular';
  src: url('/fonts/DMSans-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Spline Sans Mono';
  src: url('/fonts/SplineSansMono-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Anderson Grotesk';
  src: url('/fonts/AndersonGrotesk.otf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Anderson Grotesk Light';
  src: url('/fonts/AndersonGrotesk-Light.otf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Anderson Grotesk Bold';
  src: url('/fonts/AndersonGrotesk-Bold.otf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Exo';
  src: url('/fonts/Exo-SemiBold.ttf');
  font-weight: 600;
}

body {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.page-container {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Anderson Grotesk Light', sans-serif;

  .content-container {
    min-height: 100vh;
    margin: 0 auto;
  }
}

.blocklens-api-container {
  background: $bg-1;
}

.pricing-container,
.about-us-container {
  background-image: url('/images/bg-price-page.webp');
}

.term-and-policy {
  background-image: none;
  position: relative;
  overflow-x: inherit;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: $bg-1;
    height: 350px;
    width: 100%;
    z-index: -1;
  }
  @media (max-width: 767px) {
    &::before {
      height: 320px;
    }
  }
}

.icon-tone {
  width: 24px;
  height: 24px;
  background: url('/images/icon_social_media.png') -54px -10px;
}

.icon-telegram {
  width: 24px;
  height: 25px;
  background: url('/images/icon_social_media.png') -10px -10px;
}

.icon-vector-right {
  width: 12px;
  height: 12px;
  background: url('/images/icon_social_media.png') -10px -55px;
}

.name-sub-companys {
  .swiper {
    .swiper-wrapper {
      display: flex;
      align-items: center;

      .swiper-slide {
        display: flex;
        justify-content: center;

        svg {
          opacity: 0.5;
        }
      }
    }
  }
}

div.swiper-button-next {
  right: 0;
  width: 48px;
  height: 48px;
  background-image: url('/icons/next-btn.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;

  &:hover {
    background-image: url('/icons/next-btn-hover.svg');
  }
}

.swiper-button-next::after {
  display: none;
}

div.swiper-button-prev {
  left: 0;
  width: 48px;
  height: 48px;
  background-image: url('/icons/pre-btn.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;

  &:hover {
    background-image: url('/icons/pre-btn-hover.svg');
  }
}

.swiper-button-prev::after {
  display: none;
}

.control-swiper {
  .swiper {
    display: flex;
    flex-direction: row;

    .swiper-wrapper {
      visibility: hidden;
      width: 0;
      height: 0;
      overflow: hidden;
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: static;
      width: 48px;
      height: 48px;
      margin-top: 0;
    }
  }
}

.milestones-desc {
  .vertical-timeline {
    width: 100% !important;
    padding: 0;

    &::before {
      background: #3b528a;
    }
  }

  .vertical-timeline-element {
    .icon-timeline {
      width: 100%;
      height: 100%;
    }

    .vertical-timeline-element-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      top: 50%;
      transform: translateY(-50%);
      animation: none !important;
    }

    .vertical-timeline-element-content {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0%) -24.6%,
        rgba(34, 108, 255, 24%) 100%
      );
      border: 1px solid rgba(34, 108, 255, 30%);
      border-radius: 20px;
      font-family: 'Anderson Grotesk Light', sans-serif;
      box-shadow: none;
      backdrop-filter: blur(8px);

      .vertical-timeline-element-content-arrow {
        top: 50% !important;
        transform: rotate(0) !important;
        border: 0;
        width: 11.4%;
        height: 2px;
        background-color: #3b528a;
      }

      .element-timeline {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          margin-right: 24px;
          max-width: 70%;
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 84px;
          height: 84px;
          background: linear-gradient(
            268.85deg,
            #226cff 22.48%,
            #1084ff 83.59%
          );
          border-radius: 9999px;
        }
      }

      &:hover {
        filter: drop-shadow(0 10px 50px rgba(34, 108, 255, 50%));
        box-shadow: 0 10px 10px #226cff4d;
        cursor: pointer;
      }
    }
  }
}

.bg-logo-ekoios {
  width: 150px;
  height: 30px;
  background: url('/images/logo-company-sprites.png') -10px -132px;
}

.bg-logo-icetea {
  width: 162px;
  height: 30px;
  background: url('/images/logo-company-sprites.png') -165px -10px;
}

.bg-logo-sotatek {
  width: 135px;
  height: 50px;
  background: url('/images/logo-company-sprites.png') -10px -10px;
}

.bg-logo-sotanext {
  width: 153px;
  height: 32px;
  background: url('/images/logo-company-sprites.png') -10px -80px;
}

.bg-logo-ekoios-hover {
  width: 150px;
  height: 30px;
  background: url('/images/logo-company-hover-sprites.png') -10px -132px;
}

.bg-logo-icetea-hover {
  width: 162px;
  height: 30px;
  background: url('/images/logo-company-hover-sprites.png') -165px -10px;
}

.bg-logo-sotanext-hover {
  width: 153px;
  height: 32px;
  background: url('/images/logo-company-hover-sprites.png') -10px -80px;
}

.bg-logo-sotatek-hover {
  width: 135px;
  height: 50px;
  background: url('/images/logo-company-hover-sprites.png') -10px -10px;
}

.select-app {
  &__control {
    @include text-medium-regular;

    background-color: $bg2 !important;
    border-color: $line !important;
    height: 48px;
    border-radius: 6px !important;
    cursor: pointer;

    &:focus {
      border-color: $main;
    }

    .label-option {
      color: $title;
    }
  }
}

.icon-arrow-down {
  width: 10px;
  height: 5px;
  background: url('/images/icon_app-sprites.png') -46px -96px;
}

.app-select {
  position: relative;
  z-index: 2;

  &__btn-select {
    @include text-medium-regular;

    border: 1px solid $line;
    align-items: center;
    border-radius: 6px;
    z-index: 2;
    background-color: 'transparent';
    justify-content: space-between;
    cursor: pointer;

    .icon {
      margin-right: 10px;
    }
  }

  &.small {
    .app-select__btn-select {
      height: 32px;
      padding: 0 16px;
    }
  }

  &.medium {
    .app-select__btn-select {
      height: 40px;
      padding: 0 20px;
    }
  }

  &.large {
    .app-select__btn-select {
      height: 48px;
      padding: 0 24px;
    }

    .app-select__menu {
      top: 47px;
    }
  }

  &__menu {
    position: absolute;
    background: $border;
    border-radius: 8px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 10px;
    z-index: -1;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__menu-item {
    @include text-medium-regular;

    cursor: pointer;
    padding: 8px 18px;
    align-items: center;

    &:hover {
      background-color: $bg2;
    }

    &.selected {
      background: $gradient;
    }

    .icon {
      margin-right: 10px;
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.react-select-container {
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__value-container {
    .react-select__single-value,
    .react-select__input {
      color: $title !important;
    }
  }

  .react-select__control {
    height: 3rem;
    color: $title;
    box-shadow: none;
    background-color: transparent;
    border: 1px solid #465065;

    &:hover {
      border: 1px solid #465065;
    }
  }

  .react-select__menu {
    margin-top: 0;
    background: $border;
    border-radius: 8px;

    .react-select__menu-list {
      &::-webkit-scrollbar {
        display: none;
      }

      .react-select__option {
        &:hover {
          cursor: pointer;
        }
      }

      .react-select__option--is-focused,
      .react-select__option--is-selected {
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0%) -24.6%,
          rgba(34, 108, 255, 24%) 100%
        );
        background-color: transparent;
      }
    }
  }
}

@media (min-width: 992px) {
  .my-swiper-custom {
    .swiper-wrapper {
      margin-left: -11% !important;
    }
  }
}

@media (min-width: 1441px) {
  .my-swiper-custom {
    .swiper-wrapper {
      margin-left: -12% !important;
    }
  }
}

@media (min-width: 1600px) {
  .my-swiper-custom {
    .swiper-wrapper {
      margin-left: -22.5% !important;
    }
  }
}
